import React, { useEffect } from 'react'
import './projectads.css'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import { Autoplay,Navigation, EffectFade} from 'swiper/modules';
import AOS from 'aos'
import 'aos/dist/aos.css'

import prjct1 from '../../assets/image4.jpg'
import prjct2 from '../../assets/prjct.jpg'
import prjct3 from '../../assets/prjct2.webp'
const Projectads = () => {
  useEffect(()=>{
AOS.init({duration:1000})
  },[])
  return (
    <div className="projectads" id='projects'>
        <div className="container conatiner_project">
        <div className="header">
          <h1 className='aaHeading' data-aos="fade-up">Project Timeline and Execution</h1>
          {/* <Link className='aboutLink' to='/project'  data-aos="fade-up">Project</Link> */}
        </div>
        <div className="line"></div>
      <h2 className='heading_two' data-aos="fade-up">Designing the Extraordinary, Project by Project</h2>

      <Swiper 
        rewind={true}
        navigation={true}
        modules={[Navigation,Autoplay,EffectFade]}
        effect={'fade'}// Set the fade effect
        fadeEffect={{ crossFade: true }}
        className="mySwiper"
        autoplay={{ delay: 2000 }}
      >
        {/* <SwiperSlide className='swi'>
          <img src="https://wallpaperaccess.com/full/2076086.jpg" alt="" />
          <h1 className='swidesc' >Tropical Paradise</h1>
        </SwiperSlide> */}
        <SwiperSlide className='swi'><img src={prjct1} alt="" />
         <h1 className='swidesc'  >Modern Elegance</h1>
        </SwiperSlide>
        <SwiperSlide className='swi'><img src={prjct1} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide>
        <SwiperSlide className='swi'><img src={prjct2} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide>
        <SwiperSlide className='swi'><img src={prjct3} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide>
        {/* <SwiperSlide className='swi'><img src={} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide>
        <SwiperSlide className='swi'><img src={} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide>
        <SwiperSlide className='swi'> <img src={} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide>
        <SwiperSlide className='swi'><img src={} alt="" />
        <h1 className='swidesc' >Modern Elegance</h1></SwiperSlide> */}
      </Swiper>
        </div>
   
    </div>
  )
}

export default Projectads