import React, { useEffect } from 'react'
import {Swiper,SwiperSlide} from 'swiper/react'; 
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './carousel.css';
import pic1 from '../../assets/image6.jpg'
import pic2 from '../../assets/image1.jpg'
import pic3 from '../../assets/image6.jpg'
import pic4 from '../../assets/image6.jpg'
import pic5 from '../../assets/image6.jpg'
import pic6 from '../../assets/image6.jpg'
import pic7 from '../../assets/dorewell.avif'
import pic8 from '../../assets/dorewell2.jpg'
import pic9 from '../../assets/dorewl3.avif'
import pic10 from '../../assets/borewll4.webp'

import AOS from 'aos'
import 'aos/dist/aos.css'
import {Autoplay, EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

const Carousel = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  return (
    <div className="Container">

    <Swiper
      effect={'coverflow'}
      grabCursor={true}
  
      centeredSlides={true}
      loop={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
      }}
      pagination={{ el: '.swiper-pagination', clickable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true,
      }}
      modules={[Autoplay,EffectCoverflow, Pagination, Navigation]}
      autoplay={{ delay: 2000 }}
      className="swiper_container"
    >
      <SwiperSlide>
        <img className='cimg' src={pic1} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img className='cimg' src={pic2}alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img className='cimg' src={pic7} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img className='cimg' src={pic8} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img className='cimg' src={pic6} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img className='cimg'  src={pic9} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img className='cimg' src={pic10} alt="slide_image" />
      </SwiperSlide>

      <div className="slider-controler">
        <div className="swiper-button-prev slider-arrow">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </div>
        <div className="swiper-button-next slider-arrow">
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>
  </div>
  )
}

export default Carousel