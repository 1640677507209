import React, { useEffect } from 'react'
import './footer.css';
import AOS from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
          },[])
  return (
    <div className="footer">
        <div className="container container_ft" data-aos="fade-up">
            <div className="items">
               <h3>Kakkanad</h3>
               <h5>9656131999</h5>
               <h5>9562888667</h5>
              
     
            </div>
            <div className="items">
               <h3>Muvattupuzha</h3>
               <h5>9656131999</h5>
               <h5>9562888667</h5>
             
         
            </div>
            <div className="items">
               <h3>Perumbavoor</h3>
               <h5>9656131999</h5>
               <h5>9562888667</h5>
            
        
            </div>
        </div>
    </div>
  )
}

export default Footer