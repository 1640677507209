import React, { useEffect } from 'react'
import './contactad.css'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import pic1 from '../../assets/higlight.jpg'

const Contactads = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  return (
    <div className="contactads" id='contact'>
        <div className="container">
        <div className="header">
          <h1 className='aaHeading'  data-aos="fade-up">Get in Touch</h1>
          {/* <Link className='aboutLink' to='/contact'  data-aos="fade-up">Contact</Link> */}
        </div>
        <div className="line"></div>
      <h2 className='heading_two'  data-aos="fade-up">Building Relationships, One Conversation at a Time</h2>
        </div>
       <div className="desc">
        <img  src={pic1} alt="" />
        <div className="cont">
        <h1 className='descHeading'  data-aos="fade-up">Have a project in mind</h1>
        {/* <button className='btn' data-aos="fade-up">Let's Talk</button> */}
        <a href="https://wa.me/9656131999"   target="_blank" className='btn' data-aos="fade-up">Let's Talk</a>
        {/* <a href="https://wa.me/9656131999" target="_blank">
  <FaWhatsapp className='icon' />
</a> */}
        </div>
     
       </div>
    </div>
  )
}

export default Contactads