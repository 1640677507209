import React, { useEffect, useState } from 'react'
import './aboutads.css';
import { Link } from 'react-router-dom'
import pic1 from '../../assets/higlight.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import lice from '../../assets/license.jpg'
const Aboutads = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  const [showHistory, setShowHistory] = useState(true);
  const [showMission, setShowMission] = useState(false);
  const [showVision, setShowVision] = useState(false);
  const [activeSection, setActiveSection] = useState('History');

  const toggleHistory = () => {
    setShowHistory(true);
    setShowMission(false);
    setShowVision(false);
    setActiveSection('History');
  };

  const toggleMission = () => {
    setShowHistory(false);
    setShowMission(true);
    setShowVision(false);
    setActiveSection('Mission');
  };

  const toggleVision = () => {
    setShowHistory(false);
    setShowMission(false);
    setShowVision(true);
    setActiveSection('Vision');
  };
  return (
    <div className="adabout" id='about'>
      <div className="container container_adabout">
      <div className="header">
          <h1 className='aaHeading ' data-aos="fade-up">About Our Design Philosophy</h1>
          {/* <Link className='aboutLink' to='/about' data-aos="fade-up">About</Link> */}
        </div>
      <div className="line"></div>
      <h2 className='heading_two'>Your Vision, Our Design Expertise</h2>
        <div className="sec">
          <img className='adaboutImg' data-aos="fade-up" src={pic1} alt="" />
           <div className="section1">
           <div className="section">
            <div className="secItems">
            <h2 onClick={toggleHistory}     className={activeSection === 'History' ? 'active' : ''} >History</h2>
            <h2 onClick={toggleVision}      className={activeSection === 'Vision' ? 'active' : ''}>Vision</h2>
            <h2 onClick={toggleMission}   className={activeSection === 'Mission' ? 'active' : ''}>Mission</h2>
            </div>

        {showHistory && (
          <p className='secPara' data-aos="fade-up">
        The Kerala State Water Authority's permission license number is as follows: [GWD/003(N)/2023/EKM].
          </p>
        )}
      </div>

      <div className="section">

        {showMission && (
          <p className='secPara' data-aos="fade-up">
       To provide sustainable access to clean water through efficient borewell solutions, ensuring the well-being and prosperity of communities we serve
          </p>
        )}
      </div>

      <div className="section">

        {showVision && (
         
          <p className='secPara' data-aos="fade-up">
To be the leading provider of reliable and environmentally conscious borewell services, empowering communities with access to safe and abundant water resources.
          </p> 
       
     
        )}
      </div>
           </div>
        </div>
      </div>
      
    </div>
  )
}

export default Aboutads