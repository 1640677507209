
import './ads.css'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Ads = () => {
  useEffect(()=>{
    AOS.init({duration:1000})
      },[])
  return (
    <section className='ads' id='services' >
      <div className="container container_ads">
      <div className="header">
          <h1 className='ads_heading' data-aos="fade-up">Explore Our Borewell Solutions</h1>
          {/* <Link className='aboutLink' to='/services' data-aos="fade-left">Services</Link> */}
        </div>
      <div className="line"></div>
      <h2 className='ads_sub' data-aos="fade-up">Delivering Borewell Solutions That Leave a Lasting Impact</h2>
       
       <div className="content">
        <div className="item"  data-aos="fade-up">
            <h1>30 Years</h1>
            <h4> 2000 Clients Around the World</h4>
        </div>
        <div className="item"  data-aos="fade-up">
            <h1>2000+</h1>
            <h4>Projects Completed</h4>
        </div>
       </div>
       <div className="list">
         <ul className='lists'  data-aos="fade-up" >
              <li className='lItems'>+ Agricultural Training Services</li>
              <li className='lItems'>+ Borewell Contractors For Drinking Water</li>
              <li className='lItems'>+ Industrial Borewell Contractors</li>
         </ul>
         <ul className='lists'  data-aos="fade-up" >
              <li className='lItems'>+ Mini Borewell Drilling Contractors</li>
              <li className='lItems'>+ Tubewell Drilling Services</li>
              <li className='lItems'>+ Borewell Maintenance</li>
         </ul>
         <ul className='lists'  data-aos="fade-up">
              <li className='lItems'>+ Borewell Decommissioning</li>
              <li className='lItems'>+ Borewell water testing</li>
              <li className='lItems'>+ Borewell recharging</li>
         </ul>
       </div>
      </div>
     
    </section>
  )
}

export default Ads