import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsPerson } from 'react-icons/bs'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { FaFacebook, FaInstagram, FaPinterest, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import logo from '../assets/logoletter.png'

import { Link } from 'react-scroll'

import './navbar.css'

function Navbar() {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)

    return (
        <div name='home' className={nav ? 'navbar navbar-bg' : 'navbar'}>
            <div className={nav ? 'logo dark' : 'logo'}>
                {/* <h2 className='heading' style={{color:"black"}} >A-ONE.</h2> */}
                <img  style={{height:'155px',width:'100%',objectFit:'cover'}} src={logo} alt="" />
            </div>
            <ul className="nav-menu">
            {/* <Link   to='home' smooth={true} duration={500} ><li>Home</li></Link>
                <Link to='#about ' smooth={true} duration={500} ><li>About US</li></Link>
                <Link to='#services' smooth={true} duration={500} ><li>Services</li></Link>
                <Link to='#projects' smooth={true} duration={500} ><li>Projects</li></Link>
                <Link to='#contact' smooth={true} duration={500} ><li>Contact</li></Link> */}
           <a href='#'  className='menu-link'>Home</a>
           <a href='#about'   className='menu-link'  >About</a>
           <a href='#services'  className='menu-link' >Services</a>
      <a href='#projects'  className='menu-link' >Projects</a>
      <a href='#contact'  className='menu-link' >Contact</a>
            </ul>
            <div className="nav-icons">
                {/* <BiSearch className='icon' style={{ marginRight: '1rem' }} /> */}
                {/* <BsPerson className='icon'></BsPerson> */}
            </div>
            <div className="hamburger" onClick={handleNav}>
                {!nav ? (<HiOutlineMenuAlt4 className='icon' color='black'  />) : (<AiOutlineClose style={{ color: 'black' }} className='icon' />)}
            </div>
            <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
                <div className="mobile-nav">
                {/* <Link to='home' className='lin' smooth={true} duration={500} ><li className='link-item'>Home</li></Link>
                <Link to='about ' className='lin' smooth={true} duration={500} ><li>About US</li></Link>
                <Link to='services' className='lin' smooth={true} duration={500} ><li>Services</li></Link>
                <Link to='projects' className='lin' smooth={true} duration={500} ><li>Projects</li></Link>
                <Link to='contact' className='lin' smooth={true} duration={500} ><li>Contact</li></Link> */}
      <a href='#' onClick={handleNav} className='menu-link'>Home</a>
      <a href='#about' onClick={handleNav}  className='menu-link'  >About</a>
      <a href='#services' onClick={handleNav} className='menu-link' >Services</a>
      <a href='#projects' onClick={handleNav} className='menu-link' >Projects</a>
      <a href='#contact' onClick={handleNav} className='menu-link' >Contact</a>
                </div>
                <div className="mobile-menu-bottom">
                    <div className="menu-icons">
                        <span>PH:9656131999</span>
                    </div>
                    <div className="social-icons">
                        {/* <FaFacebook  className='icon' />
                        <FaInstagram  className='icon' /> */}
                        <a onClick={handleNav} href="https://wa.me/9656131999" target="_blank">
  <FaWhatsapp className='icon' />
</a>
                        {/* <FaWhatsapp  className='icon' /> */}
                        {/* <FaPinterest className='icon' />
                        <FaYoutube className='icon' /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
